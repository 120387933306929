import { SVGProps, Ref, forwardRef } from 'react'

const SvgFacebook = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M23 11.994c0-6.075-4.925-11-11-11s-11 4.925-11 11c0 5.49 4.022 10.04 9.281 10.867v-7.687H7.488v-3.18h2.793V9.57c0-2.757 1.643-4.28 4.155-4.28 1.204 0 2.463.215 2.463.215v2.707h-1.387c-1.366 0-1.793.847-1.793 1.718v2.064h3.05l-.487 3.18h-2.563v7.687C18.979 22.036 23 17.485 23 11.994Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgFacebook)
export default ForwardRef
